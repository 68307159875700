import PropTypes from "prop-types";
import React from "react";
import initial from "lodash/initial";
import { FormattedMessage } from "react-intl";
import DurationLabel from "app/pages/.shared/DurationLabel";
import "./AvailableDurations.scss";

const AvailableDurations = ({ durations = [] }) => {
	let durationsNode = <DurationLabel duration={durations[0]} />;

	if (durations.length > 1) {
		const firstDurations = initial(durations).join(", ");
		const lastDuration = durations[durations.length - 1];

		durationsNode = (
			<FormattedMessage
				values={{
					firstDurations,
					lastDuration,
				}}
				id="offers.available.duration.nights"
			/>
		);
	}

	return durations.length > 0 ? (
		<div className="available-durations">
			<span className="available-durations__prefix">
				<FormattedMessage id="offers.available.duration.label" />
			</span>
			<span className="available-durations__values">{durationsNode}</span>
		</div>
	) : (
		false
	);
};

AvailableDurations.propTypes = {
	durations: PropTypes.arrayOf(PropTypes.number),
};

export default AvailableDurations;
