import React from "react";
import PropTypes from "prop-types";
import "./AvisVerifiesStars.scss";

const AvisVerifiesStars = ({ rating }) => {
	return (
		<div className="avis-verifies-stars">
			<div
				style={{ clipPath: `inset(0px ${100 - rating + 5}% 0px 0px)` }}
				className="avis-verifies-stars__rating"
			/>
		</div>
	);
};

AvisVerifiesStars.propTypes = {
	rating: PropTypes.number,
};

export default React.memo(AvisVerifiesStars);
