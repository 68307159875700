import React from "react";
import PropTypes from "prop-types";
import "./AvailablePaymentTerms.scss";
import initial from "lodash/initial";
import { FormattedMessage, useIntl } from "react-intl";
import IconPaiement2x from "app/pages/.shared/static/icons/IconPaiement2x";
import IconPaiement4x from "app/pages/.shared/static/icons/IconPaiement4x";
import IconDeposit from "app/pages/.shared/IconDeposit";

const AvailablePaymentTerms = ({ availablePaymentTerms, isDepositEnabled }) => {
	const intl = useIntl();

	const availablePaymentTermsToDisplay = availablePaymentTerms
		.filter(term => term !== 1)
		.map(term => {
			if (term === 2 && isDepositEnabled) {
				return "Deposit";
			}
			return term !== 1
				? intl.formatMessage({ id: "general.payment.term.short" }, { term })
				: false;
		});

	let availablePaymentTermsNode = availablePaymentTermsToDisplay;

	if (availablePaymentTermsToDisplay.length > 1) {
		const firstTerms = initial(availablePaymentTermsToDisplay).join(", ");
		const lastTerms = availablePaymentTermsToDisplay[availablePaymentTermsToDisplay.length - 1];

		availablePaymentTermsNode = (
			<FormattedMessage
				values={{
					firstTerms,
					lastTerms,
				}}
				id="available.payment.terms.list"
			/>
		);
	}

	return (
		availablePaymentTerms.length > 1 && (
			<div className="available-payment-terms">
				<div className="available-payment-terms__title">
					<FormattedMessage
						id="available.payment.terms.prefix"
						values={{
							terms: (
								<strong className="available-payment-terms__terms-label">
									{availablePaymentTermsNode}
								</strong>
							),
						}}
					/>
				</div>

				<div className="available-payment-terms__pictos">
					{availablePaymentTerms.map(term => {
						let picto = false;

						if (term === 2) {
							if (isDepositEnabled) {
								picto = <IconDeposit />;
							} else {
								picto = <IconPaiement2x />;
							}
						} else if (term === 4) {
							picto = <IconPaiement4x />;
						}

						return (
							<div key={term} className="available-payment-terms__picto">
								{picto}
							</div>
						);
					})}
				</div>
			</div>
		)
	);
};

AvailablePaymentTerms.propTypes = {
	availablePaymentTerms: PropTypes.array,
	isDepositEnabled: PropTypes.bool,
};

AvailablePaymentTerms.defaultProps = {
	availablePaymentTerms: [],
};

export default React.memo(AvailablePaymentTerms);
