import PropTypes from "prop-types";
import React from "react";
import List from "app/pages/.shared/List";
import "./AlertMessage.scss";
import classNames from "classnames";
import { ALERT_TYPE } from "app/constants";
import { alertTypeProptypes } from "app/utils/propTypes";

const AlertMessage = ({ errors, message, alertType, children }) => {
	return (
		<div
			className={classNames(
				{ "alert-message--error": alertType === ALERT_TYPE.ERROR },
				"alert-message"
			)}
		>
			<div className="alert-message__message">
				{alertType !== ALERT_TYPE.ERROR && <i className="icon icon--info-blue" />}
				<div className="alert-message__text" data-cy="alert-message">
					{message}
				</div>
			</div>
			{children}
			{errors.length > 0 ? <List items={errors} /> : false}
		</div>
	);
};

AlertMessage.defaultProps = {
	errors: [],
};

AlertMessage.propTypes = {
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	errors: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
	alertType: alertTypeProptypes,
};

export default AlertMessage;
