import React, { useCallback } from "react";
import PropTypes from "prop-types";
import "./BackButton.scss";
import { FormattedMessage } from "react-intl";
import RelativeLink from "app/pages/.shared/RelativeLink";
import IconBack from "app/pages/.shared/static/icons/IconBack";
import { sendTagOnBackButton } from "app/utils/analytics";
import { useLocation } from "react-router-dom";

const BackButtonComponent = ({ to, label, forceBrowserBack, onClick }) => {
	const { pathname } = useLocation();

	const handleClick = useCallback(() => {
		sendTagOnBackButton(pathname);
		onClick();
	}, [pathname]);

	const backHistory = useCallback(() => {
		if (typeof window !== "undefined") {
			onClick();
			window.history.back();
		}
	}, []);

	if (forceBrowserBack) {
		return (
			<div className="back-button" onClick={backHistory} data-cy="back-button">
				<IconBack width={15} height={15} style={{ marginRight: "5px" }} />
				<div className="back-button__label">{label && <FormattedMessage id={label} />}</div>
			</div>
		);
	}

	return (
		<div className="back-button">
			<RelativeLink
				design="naked"
				to={{ ...to }}
				replace
				onClick={handleClick}
				data-cy="back-button"
			>
				<IconBack width={15} height={15} style={{ marginRight: "5px" }} />
				<div className="back-button__label">{label && <FormattedMessage id={label} />}</div>
			</RelativeLink>
		</div>
	);
};

BackButtonComponent.propTypes = {
	to: PropTypes.object,
	onClick: PropTypes.func,
	label: PropTypes.string,
	forceBrowserBack: PropTypes.bool,
};

BackButtonComponent.defaultProps = {
	onClick: () => {},
	to: {},
};

export const BackButton = React.memo(BackButtonComponent);
