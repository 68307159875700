import PropTypes from "prop-types";
import React from "react";
import Alert from "app/pages/.shared/Alert/Alert";
import Modal from "react-modal";

// @see https://reactcommunity.org/react-modal/#documentation
const style = {
	overlay: {
		backgroundColor: "rgba(43, 56, 63, .9)",
		zIndex: 6,
	},

	content: {
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
		border: "none",
		background: "none",
		overflow: "hidden",
		padding: "50px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
};

const INITIAL_STATE = {
	isOpened: false,
	alertProps: {
		title: "",
		message: "",
		buttonLabel: "",
	},
};
export const withAlert = WrappedComponent => {
	const AlertContainer = class AlertContainer extends React.Component {
		constructor(props) {
			super(props);
			this.onConfirm = this.onConfirm.bind(this);
			this.closeAlert = this.closeAlert.bind(this);
			this.showAlert = this.showAlert.bind(this);
			this.state = INITIAL_STATE;
		}

		onConfirm() {
			this.closeAlert();
			if (typeof this.props.onConfirm === "function") {
				this.props.onConfirm();
			}
		}

		closeAlert() {
			this.setState(() => INITIAL_STATE);
		}

		showAlert(alertProps) {
			this.setState(() => ({
				isOpened: true,
				alertProps,
			}));
		}

		render() {
			return (
				<div>
					<WrappedComponent
						closeAlert={this.closeAlert}
						showAlert={this.showAlert}
						{...this.props}
					/>
					<Modal isOpen={this.state.isOpened} style={style}>
						<Alert
							{...this.state.alertProps}
							onConfirm={this.onConfirm}
							onClose={this.closeAlert}
						/>
					</Modal>
				</div>
			);
		}
	};

	AlertContainer.propTypes = {
		onConfirm: PropTypes.func,
		closeAlert: PropTypes.func,
		alert: PropTypes.object,
		renderComponent: PropTypes.func,
	};
	return AlertContainer;
};
