import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AvisVerifies from "app/pages/.shared/AvisVerifies/AvisVerifies";
import { getBrandDisplayName } from "app/reducers/partnerSelector";

const AvisVerifiesContainer = props => {
	const { avisVerifies } = props;
	return (
		<AvisVerifies
			{...props}
			rating={avisVerifies.rating}
			reviewsCount={avisVerifies.numReviews}
		/>
	);
};

AvisVerifiesContainer.propTypes = {
	avisVerifies: PropTypes.object,
};

AvisVerifiesContainer.defaultProps = {
	avisVerifies: {},
};

const mapStateToProps = state => {
	return {
		brandDisplayName: getBrandDisplayName(state),
		avisVerifies: state.partner.avisVerifies,
	};
};

export default connect(mapStateToProps)(AvisVerifiesContainer);
