/* eslint-disable react/style-prop-object */
import PropTypes from "prop-types";
import React from "react";
import "./amount.scss";
import { FormattedMessage, FormattedNumber } from "react-intl";

/**
 * @see https://github.com/yahoo/react-intl/wiki/Components#formattednumber
 */
const Amount = ({ amount, currencyCode, prefix, suffix, shop }) => {
	const prefixNode = prefix ? <span className="amount__prefix">{prefix}</span> : false;
	const suffixNode = suffix ? <span className="amount__suffix">{suffix}</span> : false;

	// On veut que le libellé "Prix indisponible" ait le meme style que amount__prefix
	return (
		<div className="amount">
			{isNaN(amount) ? (
				<div className="amount__prefix">
					<FormattedMessage id="offer.price.unavailable" />
				</div>
			) : (
				<div>
					{prefixNode}
					<FormattedNumber
						value={amount}
						style="currency"
						currency={currencyCode}
						currencyDisplay="symbol"
						useGrouping={true}
						minimumFractionDigits={0}
						maximumFractionDigits={2}
					>
						{money => {
							let amount = money;

							if (currencyCode === "CHF") {
								// Sur IE 11 Win 7, le navigateur affiche "fr." au lieu de CHF
								amount = money.replace(/fr\./, "CHF");
							}

							// @see https://bugs.chromium.org/p/chromium/issues/detail?id=1174071
							if (currencyCode === "GBP" && shop === "en-GB") {
								if (money.endsWith("£GB")) {
									amount = money.replace(/\s£GB/, "");
									amount = `£${amount}`;
								}
							}

							return <span className="amount__value">{amount}</span>;
						}}
					</FormattedNumber>
					{suffixNode}
				</div>
			)}
		</div>
	);
};

Amount.propTypes = {
	shop: PropTypes.string,
	amount: PropTypes.number,
	currencyCode: PropTypes.string,
	suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default React.memo(Amount);
