import React from "react";
import "./AsideProductFiltersMenu.scss";
import { FormattedDate, FormattedMessage } from "react-intl";
import Button from "app/pages/.shared/form/Button";
import { BackButton } from "app/pages/.shared/BackButton/BackButton";
import camelCase from "lodash/camelCase";
import upperCase from "lodash/upperCase";
import includes from "lodash/includes";
import { PRODUCTS_FILTERS_KEY } from "app/constants";
import classNames from "classnames";
import { sendTagOnProductsFilter, sendTagOnProductsFilterRemove } from "app/utils/analytics";
import RelativeLink from "app/pages/.shared/RelativeLink";
import PropTypes from "prop-types";
import LogoBrandContainer from "app/pages/.shared/LogoBrand/LogoBrandContainer";
import AdvancedSelect from "app/pages/.shared/form/AdvancedSelect";

const PAGES = {
	MENU: "menu",
	DESTINATION: "destination",
	CALENDAR: "calendar",
	TOPIC: "theme",
};

const PAGE_FILTER_KEYS = {
	[PAGES.DESTINATION]: [PRODUCTS_FILTERS_KEY.DESTINATION, PRODUCTS_FILTERS_KEY.ZONE],
	[PAGES.CALENDAR]: [PRODUCTS_FILTERS_KEY.PERIOD, PRODUCTS_FILTERS_KEY.MONTH],
	[PAGES.TOPIC]: [
		PRODUCTS_FILTERS_KEY.TOPIC,
		PRODUCTS_FILTERS_KEY.BADGE,
		PRODUCTS_FILTERS_KEY.DISCOUNT_PERCENTAGE,
	],
};

class AsideProductFiltersMenuComponent extends React.PureComponent {
	constructor() {
		super();
		this.state = {
			displayPage: PAGES.MENU,
		};
		this.closeMenu = this.closeMenu.bind(this);
		this.handleApplyFilterButtonClick = this.handleApplyFilterButtonClick.bind(this);
	}

	closeMenu() {
		this.setState({
			displayPage: PAGES.MENU,
		});
	}

	handleApplyFilterButtonClick() {
		this.props.hideAsideProductFilters();
		this.props.scrollToListingHeader();
	}

	render() {
		const {
			productsCount,
			topDestinationsFilterValues,
			destinationFilterValues,
			periodFilterValues,
			monthsFilterValues,
			topicFilterValues,
			zoneFilterValues,
			badgesFilterValues,
			productsFilters,
			visibleFilters,
			productsActiveFilters,
			setProductsFilter,
			unsetProductsFilter,
			selectedFacets,
			hideAsideProductFilters,
			topTopicFilterValues = [],
		} = this.props;

		const PAGES_ITEMS = [
			{
				icon: "icon--search",
				text: "listing.filter.destination.label",
				page: PAGES.DESTINATION,
			},
			{
				icon: "icon--calendar-number",
				text: "listing.filter.calendrier.label",
				page: PAGES.CALENDAR,
			},
			{
				icon: "icon--funnel",
				text: "listing.filter.theme.label",
				page: PAGES.TOPIC,
			},
		].filter(pageItem => pageItem);

		const isDestinationFilterActive = productsFilters[PRODUCTS_FILTERS_KEY.DESTINATION];

		const isZoneFilterActive = productsFilters[PRODUCTS_FILTERS_KEY.ZONE];

		const isMonthFilterActive = productsFilters[PRODUCTS_FILTERS_KEY.MONTH];

		const isTopicFilterActive = productsFilters[PRODUCTS_FILTERS_KEY.TOPIC];

		const isBadgeFilterActive = productsFilters[PRODUCTS_FILTERS_KEY.BADGE];

		const isPeriodFilterActive = productsFilters[PRODUCTS_FILTERS_KEY.PERIOD];

		const isDiscountPercentageFacetActive =
			productsFilters[PRODUCTS_FILTERS_KEY.DISCOUNT_PERCENTAGE];

		const isFilterPageActive = page => {
			return PAGE_FILTER_KEYS[page].reduce((acc, filterKey) => {
				return acc || productsFilters[filterKey];
			}, false);
		};

		const handleClick = page => {
			this.setState({
				displayPage: page,
			});

			PAGE_FILTER_KEYS[page].forEach(filterKey => {
				unsetProductsFilter(filterKey);
			});
		};

		const getClassName = page => {
			return classNames({
				"aside-product-filters-menu__item": true,
				"aside-product-filters-menu__item--active": isFilterPageActive(page),
			});
		};

		const getFiltersLabel = (page, defaultText) => {
			switch (page) {
				case PAGES.DESTINATION: {
					return isDestinationFilterActive || isZoneFilterActive ? (
						<div>
							{productsFilters.destination} {productsFilters.zone}{" "}
							{productsFilters.topDestination}
						</div>
					) : (
						defaultText
					);
				}

				case PAGES.CALENDAR: {
					let periodTabHeaderLabel = (
						<FormattedMessage id="listing.filter.calendrier.label" />
					);

					if (isMonthFilterActive) {
						periodTabHeaderLabel = (
							<FormattedDate
								value={Number(productsFilters.month)}
								month="long"
								year="numeric"
							>
								{monthLabel => <div>{monthLabel}</div>}
							</FormattedDate>
						);
					} else if (isPeriodFilterActive) {
						periodTabHeaderLabel = productsFilters.period;
					}

					return periodTabHeaderLabel;
				}

				case PAGES.TOPIC: {
					let topicTabHeaderLabel = <FormattedMessage id="listing.filter.theme.label" />;

					if (isTopicFilterActive) {
						topicTabHeaderLabel = productsFilters.topic;
					} else if (isBadgeFilterActive) {
						topicTabHeaderLabel = productsFilters.badge;
					} else if (isDiscountPercentageFacetActive) {
						topicTabHeaderLabel = (
							<FormattedMessage
								id="listing.filter.percentage"
								values={{ percentage: "50" }}
							/>
						);
					}

					return topicTabHeaderLabel;
				}
				default:
					return false;
			}
		};

		const getClearFilterButton = page => {
			return (
				isFilterPageActive(page) && (
					<i
						className="icon icon--circle-cross-grey"
						onClick={e => {
							e.stopPropagation();
							PAGE_FILTER_KEYS[page].forEach(filterKey => {
								unsetProductsFilter(filterKey);
							});
							sendTagOnProductsFilterRemove(page);
						}}
					/>
				)
			);
		};

		const destinationOptions = destinationFilterValues.map(destination => {
			return { value: destination, label: destination };
		});

		switch (this.state.displayPage) {
			case PAGES.MENU:
				return (
					<div className="aside-product-filters-menu aside-product-filters-menu--menu">
						<div className="aside-product-filters-menu__header">
							<div
								className="aside-product-filters-menu__close"
								onClick={hideAsideProductFilters}
							>
								<i className="icon icon--cross-dark" />
							</div>
							<RelativeLink
								to={{ pathname: "/listing" }}
								className="header__logo"
								onClick={hideAsideProductFilters}
							>
								<LogoBrandContainer />
							</RelativeLink>
						</div>
						<div className="aside-product-filters-menu__list">
							{PAGES_ITEMS.map(item => {
								return (
									<div
										key={item.page}
										className={getClassName(item.page)}
										onClick={handleClick.bind(this, item.page)}
									>
										{getClearFilterButton(item.page)}
										<div className="aside-product-filters-menu__filter-name">
											{getFiltersLabel(
												item.page,
												<FormattedMessage id={item.text} />
											)}
										</div>
										<i className={`icon ${item.icon}`} />
									</div>
								);
							})}
						</div>

						<div className="aside-product-filters-menu__action">
							<Button design="primary" onClick={this.handleApplyFilterButtonClick}>
								<FormattedMessage id="listing.filter.menu.cta.label" />
								{productsCount > 0 && (
									<span className="aside-product-filters-menu__products-count">{`(${productsCount})`}</span>
								)}
							</Button>
						</div>
					</div>
				);
			case PAGES.DESTINATION:
				return (
					<div className="aside-product-filters-menu">
						<div className="aside-product-filters-menu__header">
							<div
								className="aside-product-filters-menu__close"
								onClick={hideAsideProductFilters}
							>
								<i className="icon icon--cross-dark" />
							</div>
							<RelativeLink
								to={{ pathname: "/listing" }}
								className="header__logo"
								onClick={hideAsideProductFilters}
							>
								<LogoBrandContainer />
							</RelativeLink>
						</div>

						<BackButton noBrowserBack={true} onClick={this.closeMenu} />

						<div className="filters-facets">
							<div className="filters-facets__title">
								<FormattedMessage id="listing.filter.destination.top.subtitle" />
							</div>
							<ul className="filters-facets__list">
								{topDestinationsFilterValues &&
									topDestinationsFilterValues.map(topDestination => {
										const destinationKey = camelCase(topDestination.code);
										const available = includes(
											visibleFilters.destination,
											topDestination.label
										);
										const active = includes(
											productsActiveFilters,
											topDestination.label
										);
										const count = selectedFacets.destination[
											topDestination.label
										]
											? selectedFacets.destination[topDestination.label]
													.length
											: 0;
										const facetClassName = classNames({
											"filters-facets__item": true,
											"filters-facets__item--active": active,
											"filters-facets__item--available": available,
										});

										return (
											<li
												className={facetClassName}
												key={destinationKey}
												onClick={() => {
													setProductsFilter({
														[PRODUCTS_FILTERS_KEY.DESTINATION]:
															topDestination.label,
													});
													this.closeMenu();
													sendTagOnProductsFilter(
														"topDestination",
														topDestination.label
													);
												}}
											>
												<div>
													{topDestination.label} ({count})
												</div>
											</li>
										);
									})}
							</ul>
						</div>

						<AdvancedSelect
							placeholder={
								<FormattedMessage id="listing.filter.destination.select.placeholder" />
							}
							noOptionsMessage={() => (
								<FormattedMessage id="listing.filter.destination.empty.select.placeholder" />
							)}
							value={destinationOptions.find(destination => {
								return destination.value === productsFilters.destination;
							})}
							getOptionValue={({ value }) => value}
							getOptionLabel={({ label, value }) => {
								const count = selectedFacets.destination[value]
									? selectedFacets.destination[value].length
									: 0;

								return `${label} (${count})`;
							}}
							isClearable={false}
							isSearchable={false}
							blurInputOnSelect={true}
							onChange={({ value }) => {
								setProductsFilter({ [PRODUCTS_FILTERS_KEY.DESTINATION]: value });
								this.closeMenu();
								sendTagOnProductsFilter("destination", value);
							}}
							options={destinationOptions}
						/>

						<div className="filters-facets">
							<div className="filters-facets__title">
								<FormattedMessage id="listing.filter.destination.areas.subtitle" />
							</div>
							<ul className="filters-facets__list">
								{zoneFilterValues &&
									zoneFilterValues.map(zone => {
										const destinationKey = camelCase(zone);
										const available = includes(visibleFilters.zone, zone);
										const active = includes(productsActiveFilters, zone);
										const count = selectedFacets.zone[zone]
											? selectedFacets.zone[zone].length
											: 0;
										const facetClassName = classNames({
											"filters-facets__item": true,
											"filters-facets__item--active": active,
											"filters-facets__item--available": available,
										});

										return (
											<li
												className={facetClassName}
												key={destinationKey}
												onClick={() => {
													setProductsFilter({
														[PRODUCTS_FILTERS_KEY.ZONE]: zone,
													});
													this.closeMenu();
													sendTagOnProductsFilter("zone", zone);
												}}
											>
												<div>
													{zone} ({count})
												</div>
											</li>
										);
									})}
							</ul>
						</div>
					</div>
				);
			case PAGES.CALENDAR:
				return (
					<div className="aside-product-filters-menu">
						<div className="aside-product-filters-menu__header">
							<div
								className="aside-product-filters-menu__close"
								onClick={hideAsideProductFilters}
							>
								<i className="icon icon--cross-dark" />
							</div>
							<RelativeLink
								to={{ pathname: "/listing" }}
								className="header__logo"
								onClick={hideAsideProductFilters}
							>
								<LogoBrandContainer />
							</RelativeLink>
						</div>

						<BackButton noBrowserBack={true} onClick={this.closeMenu} />

						{visibleFilters.month.length === 0 && visibleFilters.period === 0 ? (
							<FormattedMessage id="listing.filter.no.period.facet.message" />
						) : (
							false
						)}

						{visibleFilters.month.length !== 0 || visibleFilters.period !== 0 ? (
							<div className="filters-facets">
								<div className="filters-facets__title">
									<FormattedMessage id="listing.filter.month.label" />
								</div>
								<div className="filters-facets__list">
									{Object.keys(monthsFilterValues).map(year => {
										return (
											<div key={year}>
												<div className="filters-facets__subtitle">
													{year}
												</div>
												<div className="filters-facets__months">
													{monthsFilterValues[year].map(
														monthTimestamp => {
															const today = new Date();

															const monthDate = new Date(
																Number(monthTimestamp)
															);
															const monthIndex = monthDate.getUTCMonth();

															if (monthTimestamp) {
																const available = includes(
																	visibleFilters.month,
																	monthTimestamp
																);
																const active = includes(
																	productsActiveFilters,
																	monthTimestamp
																);
																const count = selectedFacets.month[
																	monthTimestamp
																]
																	? selectedFacets.month[
																			monthTimestamp
																	  ].length
																	: 0;
																const facetClassName = classNames({
																	"filters-facets__item": true,
																	"filters-facets__item--active": active,
																	"filters-facets__item--available": available,
																});

																return (
																	<div
																		className={facetClassName}
																		key={monthIndex}
																		onClick={() => {
																			setProductsFilter({
																				[PRODUCTS_FILTERS_KEY.MONTH]: monthTimestamp,
																			});
																			this.closeMenu();
																			sendTagOnProductsFilter(
																				"month",
																				monthTimestamp
																			);
																		}}
																	>
																		<FormattedDate
																			value={Number(
																				monthTimestamp
																			)}
																			month="long"
																		>
																			{monthLabel => (
																				<div>
																					<div>
																						{monthLabel}
																					</div>
																					<div>
																						({count})
																					</div>
																				</div>
																			)}
																		</FormattedDate>
																	</div>
																);
															}
															return (
																<div
																	className="filters-facets__item"
																	key={monthIndex}
																>
																	<FormattedDate
																		timeZone="UTC"
																		value={
																			new Date(
																				today.getUTCFullYear(),
																				monthIndex - 1,
																				15
																			)
																		}
																		month="long"
																	>
																		{monthLabel => (
																			<div>
																				{monthLabel} ({0})
																			</div>
																		)}
																	</FormattedDate>
																</div>
															);
														}
													)}
												</div>
											</div>
										);
									})}
								</div>
							</div>
						) : (
							false
						)}

						{visibleFilters.month.length !== 0 || visibleFilters.period !== 0 ? (
							<div className="filters-facets">
								<div className="filters-facets__title">
									<FormattedMessage id="listing.filter.period.label" />
								</div>
								<ul className="filters-facets__list">
									{periodFilterValues.map(period => {
										const periodKey = camelCase(period);
										const available = includes(visibleFilters.period, period);
										const active = includes(productsActiveFilters, period);
										const count = selectedFacets.period[period]
											? selectedFacets.period[period].length
											: 0;
										const facetClassName = classNames({
											"filters-facets__item": true,
											"filters-facets__item--active": active,
											"filters-facets__item--available": available,
										});

										return (
											<div
												className={facetClassName}
												key={periodKey}
												onClick={() => {
													setProductsFilter({
														[PRODUCTS_FILTERS_KEY.PERIOD]: period,
													});
													this.closeMenu();
													sendTagOnProductsFilter("period", period);
												}}
											>
												<div>
													{period} ({count})
												</div>
											</div>
										);
									})}
								</ul>
							</div>
						) : (
							false
						)}
					</div>
				);
			case PAGES.TOPIC: {
				const isDiscountPercentageFacetActive = productsFilters.discountPercentage;

				const percentageCount = selectedFacets.discountPercentage.true
					? selectedFacets.discountPercentage.true.length
					: 0;

				const percentageClassname = classNames({
					"filters-facets__item": true,
					"filters-facets__percentage": true,
					"filters-facets__item--active": isDiscountPercentageFacetActive,
					"filters-facets__item--available": percentageCount > 0,
				});

				return (
					<div className="aside-product-filters-menu">
						<div className="aside-product-filters-menu__header">
							<div
								className="aside-product-filters-menu__close"
								onClick={hideAsideProductFilters}
							>
								<i className="icon icon--cross-dark" />
							</div>
							<RelativeLink
								to={{ pathname: "/listing" }}
								className="header__logo"
								onClick={hideAsideProductFilters}
							>
								<LogoBrandContainer />
							</RelativeLink>
						</div>

						<BackButton noBrowserBack={true} onClick={this.closeMenu} />

						<div className="filters-facets">
							<div className="filters-facets__title">
								<FormattedMessage id="listing.filter.topic.tendances.subtitle" />
							</div>
							<ul className="filters-facets__list">
								{topTopicFilterValues &&
									topTopicFilterValues.map(topic => {
										const topicKey = camelCase(topic);
										const available = includes(visibleFilters.topic, topic);
										const active = includes(productsActiveFilters, topic);
										const count = selectedFacets.topic[topic]
											? selectedFacets.topic[topic].length
											: 0;
										const facetClassName = classNames({
											"filters-facets__item": true,
											"filters-facets__item--active": active,
											"filters-facets__item--available": available,
										});

										return (
											<li
												className={facetClassName}
												key={topicKey}
												onClick={() => {
													unsetProductsFilter(
														PRODUCTS_FILTERS_KEY.START_AT
													);

													unsetProductsFilter(PRODUCTS_FILTERS_KEY.BADGE);

													setProductsFilter({
														[PRODUCTS_FILTERS_KEY.TOPIC]: topic,
													});
													this.closeMenu();
													sendTagOnProductsFilter("theme", topic);
												}}
											>
												{topic} ({count})
											</li>
										);
									})}
								<li
									className={percentageClassname}
									onClick={() => {
										unsetProductsFilter(PRODUCTS_FILTERS_KEY.START_AT);
										unsetProductsFilter(PRODUCTS_FILTERS_KEY.BADGE);
										unsetProductsFilter(PRODUCTS_FILTERS_KEY.TOPIC);
										setProductsFilter({
											[PRODUCTS_FILTERS_KEY.DISCOUNT_PERCENTAGE]: true,
										});
										this.closeMenu();
										sendTagOnProductsFilter("theme", "50%");
									}}
								>
									<FormattedMessage
										id="listing.filter.percentage"
										values={{ percentage: "50" }}
									/>
									<span className="filters-facets__item--count">
										({percentageCount})
									</span>
								</li>
							</ul>
						</div>

						<div className="filters-facets">
							<div className="filters-facets__title">
								<FormattedMessage id="listing.filter.product.type.subtitle" />
							</div>
							<ul className="filters-facets__list">
								{topicFilterValues.map(topic => {
									const topicKey = camelCase(topic);
									const available = includes(visibleFilters.topic, topic);
									const active = includes(productsActiveFilters, topic);
									const count = selectedFacets.topic[topic]
										? selectedFacets.topic[topic].length
										: 0;
									const facetClassName = classNames({
										"filters-facets__item filters-facets__item--with-icon": true,
										"filters-facets__item": true,
										"filters-facets__item--active": active,
										"filters-facets__item--available": available,
									});

									const pictoUrl = `https://res.cloudinary.com/perfectstay/image/upload/v1525423534/icons/Incontournables/${upperCase(
										topic
									).replace(/\s/g, "_")}.svg`;

									return (
										<div
											className={facetClassName}
											key={topicKey}
											onClick={() => {
												unsetProductsFilter(PRODUCTS_FILTERS_KEY.START_AT);
												unsetProductsFilter(PRODUCTS_FILTERS_KEY.END_AT);
												setProductsFilter({
													[PRODUCTS_FILTERS_KEY.TOPIC]: topic,
												});
												this.closeMenu();
												sendTagOnProductsFilter("theme", topic);
											}}
										>
											<img src={pictoUrl} />
											{topic} ({count})
										</div>
									);
								})}
							</ul>
						</div>

						<div className="filters-facets">
							<div className="filters-facets__title">
								<FormattedMessage id="listing.filter.topic.hotelType.subtitle" />
							</div>
							<ul className="filters-facets__list">
								{badgesFilterValues.map(badge => {
									const badgeKey = camelCase(badge);
									const available = includes(visibleFilters.badge, badge);
									const active = includes(productsActiveFilters, badge);
									const count = selectedFacets.badge[badge]
										? selectedFacets.badge[badge].length
										: 0;
									const facetClassName = classNames({
										"filters-facets__item": true,
										"filters-facets__item--active": active,
										"filters-facets__item--available": available,
									});

									return (
										<div
											className={facetClassName}
											key={badgeKey}
											onClick={() => {
												unsetProductsFilter(PRODUCTS_FILTERS_KEY.START_AT);
												unsetProductsFilter(PRODUCTS_FILTERS_KEY.END_AT);
												setProductsFilter({
													[PRODUCTS_FILTERS_KEY.BADGE]: badge,
												});
												this.closeMenu();
												sendTagOnProductsFilter("badge", badge);
											}}
										>
											<div>
												{badge} ({count})
											</div>
										</div>
									);
								})}
							</ul>
						</div>
					</div>
				);
			}
			default:
				return false;
		}
	}
}

AsideProductFiltersMenuComponent.propTypes = {
	productsCount: PropTypes.number,
	topDestinationsFilterValues: PropTypes.arrayOf(
		PropTypes.shape({
			code: PropTypes.string,
			label: PropTypes.string,
		})
	),
	destinationFilterValues: PropTypes.arrayOf(PropTypes.string),
	periodFilterValues: PropTypes.arrayOf(PropTypes.string),
	monthsFilterValues: PropTypes.arrayOf(PropTypes.string),
	topicFilterValues: PropTypes.arrayOf(PropTypes.string),
	zoneFilterValues: PropTypes.arrayOf(PropTypes.string),
	badgesFilterValues: PropTypes.arrayOf(PropTypes.string),
	productsFilters: PropTypes.object,
	visibleFilters: PropTypes.object,
	productsActiveFilters: PropTypes.arrayOf(PropTypes.string),
	setProductsFilter: PropTypes.func,
	unsetProductsFilter: PropTypes.func,
	selectedFacets: PropTypes.object,
	hideAsideProductFilters: PropTypes.func,
	topTopicFilterValues: PropTypes.arrayOf(PropTypes.string),
	scrollToListingHeader: PropTypes.func,
};
export const AsideProductFiltersMenu = AsideProductFiltersMenuComponent;
