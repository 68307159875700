import PropTypes from "prop-types";
import React from "react";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import LazyLoad from "react-lazyload";
import { connect } from "react-redux";
import { addOptionsToCloundinaryURL } from "app/utils/image/cloudinaryUtils";

const MAX_WIDTHS = {
	small: 768,
	medium: 978,
	large: 1440,
	xlarge: 1440,
};

const IMG_STYLES = {
	display: "none",
};

class BackgroundProgressiveImg extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			loaded: false,
		};
		this.onImageLoad = this.onImageLoad.bind(this);
		this.cloudinarify = this.cloudinarify.bind(this);
	}

	cloudinarify() {
		let cloudinaryConfig = [];

		const resolution = this.props.resolution;

		const size = this.props.sizes && this.props.sizes[resolution];

		if (size && size.width) {
			cloudinaryConfig.push(`w_${size.width}`);
		} else {
			cloudinaryConfig.push(`w_${MAX_WIDTHS[resolution]}`);
		}

		if (size && size.height) {
			cloudinaryConfig.push(`h_${size.height}`);
		}

		if (this.props.src) {
			let options = cloudinaryConfig;

			if (this.props.cloudinaryOptions) {
				options = cloudinaryConfig.concat(this.props.cloudinaryOptions);
			}

			return addOptionsToCloundinaryURL(this.props.src, options, {
				quality: this.props.quality,
			});
		}

		return "";
	}

	onImageLoad() {
		this.setState(
			{
				loaded: true,
			},
			() => {
				if (typeof this.props.onLoad === "function") {
					this.props.onLoad();
				}
			}
		);
	}

	render() {
		const {
			thumbnail,
			sizes,
			resolution,
			backgroundPosition,
			offset,
			overflow, // utile lorsque les images apparaissent dans une modal par exemple
		} = this.props;

		const source = this.cloudinarify();

		const backgroundImageStyle = {
			backgroundSize: "cover",
			backgroundRepeat: "no-repeat",
			height: "100%",
			transition: this.state.loaded ? "opacity 1s ease" : "none",
			opacity: this.state.loaded ? 1 : 0,
			backgroundImage: `url("${source}")`,
			backgroundPosition: backgroundPosition,
		};

		return (
			<div
				className="background-progressive-image"
				style={{
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					height: "100%",
					minHeight: "100%",
					backgroundImage: `url("data:image/jpeg;base64,${thumbnail}")`,
					backgroundPosition: backgroundPosition,
				}}
			>
				{// Evite de télécharger des images inutiles lorsque la resolution n'est pas encore connue ou lorsque
				// la resolution n'est pas définie dans la props sizes
				resolution !== RESOLUTION.UNKNOWN && sizes[resolution] !== undefined ? (
					<LazyLoad once offset={offset} overflow={overflow}>
						<div
							className="background-progressive-image__image"
							style={backgroundImageStyle}
						>
							<img style={IMG_STYLES} src={source} onLoad={this.onImageLoad} />
						</div>
					</LazyLoad>
				) : (
					false
				)}
			</div>
		);
	}
}

BackgroundProgressiveImg.defaultProps = {
	backgroundPosition: "inherit",
	offset: 1000,
	thumbnail: "",
};
BackgroundProgressiveImg.propTypes = {
	src: PropTypes.string,
	sizes: PropTypes.shape({
		small: PropTypes.shape({
			height: PropTypes.number,
			width: PropTypes.number,
		}),
		medium: PropTypes.shape({
			height: PropTypes.number,
			width: PropTypes.number,
		}),
		large: PropTypes.shape({
			height: PropTypes.number,
			width: PropTypes.number,
		}),
		xlarge: PropTypes.shape({
			height: PropTypes.number,
			width: PropTypes.number,
		}),
	}),
	onLoad: PropTypes.func,
	thumbnail: PropTypes.string,
	resolution: PropTypes.string,
	backgroundPosition: PropTypes.string,
	offset: PropTypes.number,
	overflow: PropTypes.number,
	cloudinaryOptions: PropTypes.arrayOf(PropTypes.string),
	quality: PropTypes.string, // @see https://cloudinary.com/documentation/image_optimization#use_q_auto_automatic_quality_and_encoding
};

const mapStateToProps = state => {
	return {
		resolution: state.resolution,
	};
};

export default connect(mapStateToProps)(BackgroundProgressiveImg);
