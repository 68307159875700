import React from "react";
import { connect } from "react-redux";
import { getAvailablePaymentTerms } from "app/reducers/partnerSelector";
import AvailablePaymentTerms from "app/pages/.shared/AvailablePaymentTerms/AvailablePaymentTerms";
import { isDepositEnabled } from "app/pages/.shared/Deposit/depositSelector";
import { getPaymentModes } from "app/pages/Booking/Payment/paymentSelector";

const AvailablePaymentTermsContainer = props => {
	return <AvailablePaymentTerms {...props} />;
};

const mapStateToProps = (state, props) => {
	return {
		availablePaymentTerms: props.isPaymentPage
			? getPaymentModes(state)
			: getAvailablePaymentTerms(state),
		isDepositEnabled: isDepositEnabled(state),
	};
};

export default connect(mapStateToProps)(AvailablePaymentTermsContainer);
