import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedPlural } from "react-intl";
import "./AvisVerifies.scss";
import AvisVerifiesStars from "app/pages/.shared/AvisVerifies/AvisVerifiesStars";
import { removeTrailingZeroOfInteger } from "app/utils/utils";

const AvisVerifies = ({ brandDisplayName, rating, reviewsCount }) => {
	const ratingToDisplay = removeTrailingZeroOfInteger(rating * 2, 1);

	return (
		rating > 0 && (
			<div className="avis-verifies">
				<div className="avis-verifies__brand">{brandDisplayName}</div>
				<div className="avis-verifies__rating-review">
					<div className="avis-verifies__rating">{ratingToDisplay}/10</div>
					<div className="avis-verifies__review">
						(
						<FormattedPlural
							value={reviewsCount}
							one={
								<FormattedMessage
									id="product.review.count.singular"
									values={{
										reviewsCount: 1,
									}}
								/>
							}
							other={
								<FormattedMessage
									id="product.review.count.plural"
									values={{
										reviewsCount,
									}}
								/>
							}
						/>
						)
					</div>
				</div>
				<div className="avis-verifies__footer">
					<img src="https://res.cloudinary.com/perfectstay/image/upload/f_auto,q_auto,fl_lossy/v1614002317/asset/avis-verifies.png" />
					<AvisVerifiesStars rating={ratingToDisplay * 10} />
				</div>
			</div>
		)
	);
};

AvisVerifies.propTypes = {
	brandDisplayName: PropTypes.string,
	rating: PropTypes.number,
	reviewsCount: PropTypes.number,
};

export default React.memo(AvisVerifies);
