import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import "./badge.scss";

const Badge = ({ children, design, style }) => {
	const badgeClassName = classNames({
		badge: true,
		"badge--blue": design === "blue",
	});

	return (
		<div className={badgeClassName} style={style}>
			{children}
		</div>
	);
};

Badge.propTypes = {
	style: PropTypes.object,
	children: PropTypes.node,
	design: PropTypes.oneOf(["blue"]),
};

export default Badge;
